import React from 'react'

export const Footer: React.FC = () => {
  return (
	  <footer className="c-footer">
      <div className="c-footer__pagetop"><a><span>PAGE<br />TOP</span></a></div>
      <div className="c-footer__body">
        <div className="c-footer__logo">
          <p><img src="/img/logo2025_w.svg" alt="BETSUDAI" /></p>
        </div>
        <div className="c-footer__nav">
          <div className="c-footer__nav-global">
            <ul>
              <li><a href="/">トップ</a></li>
              <li><a href="/news/">新着情報</a></li>
              <li><a href="/event/">イベント情報</a></li>
              <li><a href="/afterservice/#afterservice01">お客さまサポート</a></li>
              <li><a href="/company/">企業情報</a></li>
              <li><a target="_blank" href="https://arwrk.net/recruit/hhc5p8u69gcdhd1/">採用情報</a></li>
              <li><a href="/contact/">お問い合わせ</a></li>
              <li><a href="/privacy/">プライバシーポリシー</a></li>
              <li><a href="/sitemap/">サイトマップ</a></li>
            </ul>
          </div>
          <div className="c-footer__nav-service">
            <p>サービス紹介</p>
            <ul data-level="01">
              <li>
                <p data-item="ttl"><a href="/buy/">家を、売る・買う</a></p>
                <ul data-level="02">
                  <li><span><a href="/buy/#sell">売 る</a></span>
                    <ul data-level="03">
                      <li><a href="/buy/#buy01">不動産売却</a></li>
                    </ul>
                  </li>
                  <li><span><a href="/buy/#buy">買 う</a></span>
                    <ul data-level="03">
                      <li><a href="/buy/#buy02">新築戸建</a></li>
                      <li><a href="/buy/#buy03">土地</a></li>
                      <li><a href="/buy/#buy04">新築マンション</a></li>
                      <li><a href="/buy/#buy05">中古戸建・中古マンション</a></li>
                      <li><a href="/buy/#buy06">収益物件</a></li>
                      <li><a href="/buy/#buy07">リゾート物件</a></li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <p data-item="ttl"><a href="/rent/">家を、貸す・借りる</a></p>
                <ul data-level="02">
                  <li><span><a href="/rent/#lend">貸 す</a></span>
                    <ul data-level="03">
                      <li><a href="/rent/#rent01">賃貸管理</a></li>
                    </ul>
                  </li>
                  <li><span><a href="/rent/#borrow">借りる</a></span>
                    <ul data-level="03">
                      <li><a href="/rent/#rent02">賃貸</a></li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <p data-item="ttl"><a href="/build/">家を、建てる・リフォーム</a></p>
                <ul data-level="02">
                  <li><span><a href="/build/#build">建てる</a></span>
                    <ul data-level="03">
                      <li><a href="/build/#build01">新築戸建</a></li>
                      <li><a href="/build/#build01">規格・注文</a></li>
                      <li><a href="/build/#build02">リノベーション</a></li>
                    </ul>
                  </li>
                  <li><span><a href="/build/#reform">リフォーム</a></span>
                    <ul data-level="03">
                      <li><a href="/build/#build03">リフォーム</a></li>
                    </ul>
                  </li>
                </ul>
              </li>
              <li>
                <p data-item="ttl"><a href="/afterservice/">家を、守る</a></p>
                <ul data-level="02">
                  <li><span><a href="/afterservice/#afterservice">守 る</a></span>
                    <ul data-level="03">
                      <li><a href="/afterservice/#afterservice01">お客さまサポート</a></li>
                      <li><a href="/afterservice/#afterservice02">マンション管理</a></li>
                    </ul>
                  </li>
                </ul>
              </li>
            </ul>
          </div>
        </div><small className="c-footer__copyright">©BETSUDAI</small>
      </div>
    </footer> 
  )
}
